import React, { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import {
	accessPagesState,
	creditModalState,
	endpointState,
	filterState,
	packagesDataState,
} from '../../recoil/atoms';
import BitButton from '../../components/BitButton/BitButton';
import iconArquivos from '../../assets/images/icon-arquivos.svg';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitHeaderSecondMenu from '../../components/BitHeader/BitHeaderSecondMenu';
import BitPage from '../../components/BitPage/BitPage';
import BitTable from '../../components/BitTable/BitTable';
import BitFilters from '../../components/BitFilters/BitFilters';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';
import BitTabs from '../../components/BitTabs/BitTabs';

import iconCadastro from '../../assets/images/icon-cadastro.svg';
import iconDescontos from '../../assets/images/icon-descontos.svg';
import iconNotificacao from '../../assets/images/icon-notificacao.svg';
import iconPublicacoes from '../../assets/images/icon-publicacoes.svg';
import iconReservas from '../../assets/images/icon-reservas.svg';
import iconAdicionar from '../../assets/images/icon-adicionar.svg';
import BitModal from '../../components/BitModal/BitModal';
import BitInput from '../../components/BitInput/BitInput';
import { headersTable } from './Headers';
import {
	allSubMenusRegistrations,
	subMenusRegistrations,
	typeSubMenus,
	subMenusPublicacoes,
	subMenusShopkeeperCategorias,
	subMenusVouchers,
	subMenusSolicitations,
} from './SubMenus';

let ajaxRequest: CancelTokenSource | null = null;

const Registers: React.FC = () => {
	Moment.locale('pt-br');

	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	type typeContentModal = null | JSX.Element;

	type typeDataTable = any[][];

	type typeSecondMenu = null | JSX.Element;

	type typeResponseAxios = {
		[key: string]: any;
	};

	type typeTypesRegisterAPI = {
		[key: string]: number;
	};

	type typeMaxWSizeModal =
		| 'max-w-screen-sm'
		| 'max-w-screen-md'
		| 'max-w-screen-lg'
		| 'max-w-screen-xl'
		| 'max-w-screen-2xl';

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});
	const [selectedEmpreendimento, setSelectedEmpreendimento] = useState(
		'all_developments',
	);

	interface uploadFileIface {
		lastModified: number;
		lastModifiedDate: any;
		name: string;
		size: number;
		type: string;
		webkitRelativePath: any;
	}

	const [retornoImportaWords, setRetornoImportaWords] = useState(<div />);
	const [uploadedFile, setUploadedFile] = useState<uploadFileIface>();

	const onChangeHandler = (e: any) => {
		setUploadedFile(e.target.files[0]);
		console.log(uploadedFile);
	};

	const onUploadHandler = (e: any) => {
		setRetornoImportaWords(<div />);

		const data = new FormData();
		data.append('development_id', selectedEmpreendimento.toString());
		// @ts-ignore
		data.append('file', uploadedFile);

		axios
			.post(urlEndpointImportaPalavras, data, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(function (response: typeResponseAxios) {
				if (
					response.data.message ===
					'Spreadsheet succesfully uploaded!'
				) {
					setRetornoImportaWords(
						<div className="border rounded bg-green-100 text-green-700 border-green-500">
							<p>Palavras inseridas com sucesso!</p>
						</div>,
					);
				} else {
					setRetornoImportaWords(
						<div className="border rounded bg-red-100 text-red-700 border-red-500">
							<p>
								Ocorreu um erro ao tentar importar as palavras!
							</p>
						</div>,
					);
				}
			})
			.catch(function (error) {
				setRetornoImportaWords(
					<div className="border rounded bg-red-100 text-red-700 border-red-500">
						<p>Ocorreu um erro ao tentar importar as palavras!</p>
					</div>,
				);
			});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empreendimento: any) => {
						const isSelected = false;

						newValueEmpreendimentos.push({
							value: empreendimento.id,
							label: empreendimento.name,
							selected: isSelected,
						});
						return null;
					});
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});
			});
	}, []);

	const [modalShow, setModalShow] = useRecoilState(creditModalState);

	const [labelModal, setLabelModal] = useState('');
	const [descriptionModal, setDescriptionModal] = useState('');
	const [thumbnailHeaderModal, setThumbnailHeaderModal] = useState('');
	const [retornoExportaXLSX, setRetornoExportaXLSX] = useState(<div />);
	const [retornoImportaXLSX, setRetornoImportaXLSX] = useState(<div />);
	const [maxWSizeModal, setMaxWSizeModal] = useState<typeMaxWSizeModal>(
		'max-w-screen-lg',
	);
	const [contentModal, setContentModal] = useState<typeContentModal>(null);

	const openModal = (
		e: React.FormEvent<HTMLInputElement>,
		valueDadosTabela: any,
	) => {
		e.preventDefault();

		let allTabsBitTabs = [
			{
				tabHeader: 'Empreendimento',
				tabActive: true,
				tabContent: (
					<div>
						<p>
							<b>DADOS DO EMPREENDIMENTO</b>
						</p>
						<div className="grid grid-cols-2 gap-1">
							<p className="mb-0">{`Nome do empreendimento: ${valueDadosTabela.name}`}</p>
							<p className="mb-0">{`Número: ${valueDadosTabela.number}`}</p>
							<p className="mb-0">{`Bairro: ${valueDadosTabela.neighborhood}`}</p>
							<p className="mb-0">{`Estado: ${valueDadosTabela.state}`}</p>
							<p className="mb-0">{`Complemento: ${valueDadosTabela.name}`}</p>
							<p className="mb-0">{`Cidade: ${valueDadosTabela.city}`}</p>
							<p className="mb-0">{`CEP: ${valueDadosTabela.zip_code}`}</p>
							<p className="mb-0">{`Logradouro: ${valueDadosTabela.address_line_1}`}</p>
							<p className="mb-0">{`Telefone: ${valueDadosTabela.phone}`}</p>
						</div>
					</div>
				),
			},
			{
				tabHeader: 'Descrição',
				tabActive: false,
				tabContent: (
					<div>
						<p>
							<b>DESCRIÇÃO</b>
						</p>
						{valueDadosTabela.description}
					</div>
				),
			},
			{
				tabHeader: 'Documentações',
				tabActive: false,
				tabContent: (
					<div>
						<p>
							<b>DOCUMENTAÇÔES</b>
						</p>
						<img src={valueDadosTabela.photo} alt="" />
					</div>
				),
			},
		];

		setDescriptionModal(
			`${valueDadosTabela.city}/${valueDadosTabela.state}`,
		);
		setThumbnailHeaderModal(valueDadosTabela.photo);

		if (pathName === '/registrations/shopkeepers') {
			allTabsBitTabs = [
				{
					tabHeader: 'Lojista',
					tabActive: true,
					tabContent: (
						<div>
							<p>
								<b>DADOS DO LOJISTA</b>
							</p>
							<div className="grid grid-cols-2 gap-1">
								<p className="mb-0">{`Nome do lojista: ${valueDadosTabela.name}`}</p>
								<p className="mb-0">{`Número: ${valueDadosTabela.support_user.number}`}</p>
								<p className="mb-0">{`Bairro: ${valueDadosTabela.support_user.neighborhood}`}</p>
								<p className="mb-0">{`Estado: ${valueDadosTabela.support_user.state}`}</p>
								<p className="mb-0">{`Complemento: ${valueDadosTabela.support_user.name}`}</p>
								<p className="mb-0">{`Cidade: ${valueDadosTabela.support_user.city}`}</p>
								<p className="mb-0">{`CEP: ${valueDadosTabela.support_user.zip_code}`}</p>
								<p className="mb-0">{`Logradouro: ${valueDadosTabela.support_user.address_line_1}`}</p>
								<p className="mb-0">{`Telefone: ${valueDadosTabela.support_user.phone}`}</p>
							</div>
						</div>
					),
				},
				{
					tabHeader: 'Descrição',
					tabActive: false,
					tabContent: (
						<div>
							<p>
								<b>DESCRIÇÃO</b>
							</p>
							{valueDadosTabela.support_user.description}
						</div>
					),
				},
				{
					tabHeader: 'Documentações',
					tabActive: false,
					tabContent: (
						<div>
							<p>
								<b>DOCUMENTAÇÔES</b>
							</p>
							<img
								src={valueDadosTabela.support_user.photo}
								alt=""
							/>
						</div>
					),
				},
			];

			setDescriptionModal(
				`${valueDadosTabela.support_user.city}/${valueDadosTabela.support_user.state}`,
			);
			setThumbnailHeaderModal(valueDadosTabela.support_user.photo);
		}

		setLabelModal(valueDadosTabela.name);
		setContentModal(<BitTabs allTabs={allTabsBitTabs} />);

		setMaxWSizeModal('max-w-screen-lg');
		setModalShow({
			open: !modalShow.open,
		});
	};

	const showModalExportarXLSX = () => {
		setLabelModal('Exportar XLSX');
		setDescriptionModal(``);
		setThumbnailHeaderModal('');

		setContentModal(
			<div className="form-importar-exportar my-4">
				<div className="grid grid-cols-1 gap-1">
					<BitInput
						label="Selecione o empreendimento"
						type="select"
						opcaoTodosSelect={true}
						multipleSelect={false}
						placeholder=""
						valuesSelect={empreendimentos.value}
						classField=""
						onChange={(e: React.FormEvent<HTMLSelectElement>) => {
							const allOptions = Object.entries(
								e.currentTarget.options,
							);

							allOptions.map(option => {
								if (option[1].selected) {
									setSelectedEmpreendimento(option[1].value);
								}
								return null;
							});
						}}
					/>
					<BitButton
						onClick={excutaExportacaoXLSX}
						text={`Exportar`}
						buttonColor={'#3AABA2'}
						textColor={'#FFFFFF'}
					/>
					<div className="text-center">{retornoExportaXLSX}</div>
				</div>
			</div>,
		);

		setMaxWSizeModal('max-w-screen-sm');
		setModalShow({
			open: !modalShow.open,
		});
	};

	const excutaExportacaoXLSX = () => {
		lockFields(currentFields);
		setShowCarregandoTabela('');

		setRetornoExportaXLSX(<div />);

		axios
			.get(urlEndpointExportaXLSX, {
				params: {
					development_id: selectedEmpreendimento,
				},
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response: typeResponseAxios) {
				unlockFields(currentFields);
				setShowCarregandoTabela('hidden');

				if (response.message === 'Spreadsheet succesfully exported!') {
					setRetornoExportaXLSX(
						<div className="border rounded bg-green-100 text-green-700 border-green-500">
							<p>
								O sistema está processando sua solicitação de
								exportação, isto pode levar alguns minutos mas,
								não se preocupe, iremos enviar a planilha para
								seu e-mail cadastrado em nossa base de dados.
							</p>
						</div>,
					);
				} else {
					setRetornoExportaXLSX(
						<div className="border rounded bg-red-100 text-red-700 border-red-500">
							<p>
								Ocorreu um erro ao tentar exportar os
								proprietarios!
							</p>
						</div>,
					);
				}
			})
			.catch(function (error) {
				unlockFields(currentFields);
				setShowCarregandoTabela('hidden');

				setRetornoExportaXLSX(
					<div className="border rounded bg-red-100 text-red-700 border-red-500">
						<p>
							Ocorreu um erro ao tentar exportar os proprietarios!
						</p>
					</div>,
				);
			});
	};

	const excutaImportacaoXLSX = () => {
		lockFields(currentFields);
		setShowCarregandoTabela('');

		setRetornoImportaXLSX(<div />);

		const data = new FormData();
		data.append('development_id', selectedEmpreendimento);
		// data.append('file', uploadedFile);

		axios
			.post(urlEndpointImportaXLSX, data, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(function (response: typeResponseAxios) {
				unlockFields(currentFields);
				setShowCarregandoTabela('hidden');

				if (response.message === 'Spreadsheet succesfully uploaded!') {
					setRetornoImportaXLSX(
						<div className="border rounded bg-green-100 text-green-700 border-green-500">
							<p>
								O sistema está processando sua solicitação de
								exportação, isto pode levar alguns minutos.
							</p>
						</div>,
					);
				} else {
					setRetornoImportaXLSX(
						<div className="border rounded bg-red-100 text-red-700 border-red-500">
							<p>
								Ocorreu um erro ao tentar importar os
								proprietarios!
							</p>
						</div>,
					);
				}
			})
			.catch(function (error) {
				unlockFields(currentFields);
				setShowCarregandoTabela('hidden');

				setRetornoImportaXLSX(
					<div className="border rounded bg-red-100 text-red-700 border-red-500">
						<p>
							Ocorreu um erro ao tentar importar os proprietarios!
						</p>
					</div>,
				);
			});
	};

	const [headers, setHeaders] = useState(['']);
	const [data, setData] = useState([['']]);

	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);

	const [showCarregandoTabela, setShowCarregandoTabela] = useState('hidden');

	const pathName = useLocation().pathname;

	const { permissions } = useRecoilValue(accessPagesState);

	const { urlEndpoint } = useRecoilValue(endpointState);
	let urlEndpointLista = `${urlEndpoint}/users`;
	let urlEndpointDelete = `${urlEndpoint}/users/delete/`;
	let urlEndpointExportaXLSX = `${urlEndpoint}/users/export`;
	let urlEndpointImportaXLSX = `${urlEndpoint}/users/import`;
	const urlEndpointImportaPalavras = `${urlEndpoint}/words/import`;
	const urlEndpointExportaPalavras = `${urlEndpoint}/words/export`;

	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;

	if (pathName === '/registrations/enterprises') {
		urlEndpointLista = `${urlEndpoint}/developments`;
		urlEndpointDelete = `${urlEndpoint}/developments/delete/`;
	} else if (pathName === '/reservas') {
		urlEndpointLista = `${urlEndpoint}/reservations`;
		urlEndpointDelete = `${urlEndpoint}/reservations/delete/`;
	} else if (pathName === '/descontos/vouchers') {
		urlEndpointLista = `${urlEndpoint}/vouchers`;
		urlEndpointDelete = `${urlEndpoint}/vouchers/delete/`;
	} else if (pathName === '/descontos/categorias') {
		urlEndpointLista = `${urlEndpoint}/shopkeepers/categories`;
		urlEndpointDelete = `${urlEndpoint}/shopkeepers/categories/delete/`;
	} else if (pathName === '/notificacao/lista-de-push') {
		urlEndpointLista = `${urlEndpoint}/pushs`;
		urlEndpointDelete = `${urlEndpoint}/pushs/destroy/`;
	} else if (pathName === '/publicacoes/categorias') {
		urlEndpointLista = `${urlEndpoint}/categories`;
		urlEndpointDelete = `${urlEndpoint}/categories/delete/`;
	} else if (pathName === '/enquetes') {
		urlEndpointLista = `${urlEndpoint}/pools`;
		urlEndpointDelete = `${urlEndpoint}/pools/delete/`;
	} else if (pathName === '/publicacoes') {
		urlEndpointLista = `${urlEndpoint}/publications`;
		urlEndpointDelete = `${urlEndpoint}/publications/delete/`;
	} else if (pathName === '/shortcuts') {
		urlEndpointLista = `${urlEndpoint}/shortcuts`;
		urlEndpointDelete = `${urlEndpoint}/shortcuts/delete/`;
	} else if (pathName === '/denuncias/razoes') {
		urlEndpointLista = `${urlEndpoint}/reasons`;
		urlEndpointDelete = `${urlEndpoint}/reasons/delete/`;
	} else if (pathName === '/denuncias') {
		urlEndpointLista = `${urlEndpoint}/denunciations`;
		urlEndpointDelete = `${urlEndpoint}/denunciations/delete/`;
	} else if (pathName === '/denuncias/filtrados') {
		urlEndpointLista = `${urlEndpoint}/publications?filter=true`;
		urlEndpointDelete = `${urlEndpoint}/publications/delete/`;
	} else if (pathName === '/fcg/solicitations') {
		urlEndpointLista = `${urlEndpoint}/solicitations/list`;
		urlEndpointDelete = `${urlEndpoint}/solicitations/delete/`;
	} else if (pathName === '/chat') {
		urlEndpointLista = `${urlEndpoint}/chats`;
		urlEndpointDelete = `${urlEndpoint}/chats/delete/`;
	} else if (pathName === '/packages') {
		urlEndpointLista = `${urlEndpoint}/packages`;
		urlEndpointDelete = `${urlEndpoint}/packages/delete/`;
	}

	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');

	let subMenus: typeSubMenus = {};

	const [secondMenu, setSecondMenu] = useState<typeSecondMenu>(null);

	const typesRegisterAPI: typeTypesRegisterAPI = {
		'/registrations': 1,
		'/registrations/administrators': 1,
		'/registrations/enterprises': 0,
		'/registrations/shopkeepers': 2,
		'/registrations/residents': 3,
		'/registrations/managers': 4,
		'/registrations/porters': 5,
		'/reservas': 0,
		'/denuncias': 0,
		'/denuncias/razoes': 0,
		'/denuncias/filtros': 0,
		'/denuncias/filtrados': 0,
		'/publicacoes': 0,
		'/publicacoes/categorias': 0,
		'/shortcuts': 0,
		'/enquetes': 0,
		'/descontos/vouchers': 0,
		'/notificacao/lista-de-push': 0,
		'/fcg/solicitations': 0,
	};

	const currentHeaderTable = headersTable[pathName];

	const currentTypeRegisterAPI = typesRegisterAPI[pathName];

	const constFilterState = useRecoilValue(filterState);

	let dataTable: typeDataTable;

	dataTable = [];

	let defaultLabelPaginaAtualTipo = 'Cadastros';
	let defaultLabelPaginaAtualPlural = 'Administradores';
	let defaultLabelPaginaAtualSingular = 'Administrador';
	let defaultIcon = iconCadastro;
	let defaultRouteNewRegister = '/new-register/administrador';
	let defaultRouteUpdateRegister = '/update/administrador/';

	if (pathName === '/registrations') {
		defaultLabelPaginaAtualPlural = 'Administradores';
		defaultLabelPaginaAtualSingular = 'Administrador';

		defaultIcon = iconCadastro;
	} else if (pathName === '/reservas') {
		defaultLabelPaginaAtualPlural = 'Reservas';
		defaultLabelPaginaAtualSingular = 'Reserva';

		defaultIcon = iconReservas;

		defaultRouteNewRegister = '/new-register/reserva';
		defaultRouteUpdateRegister = '/update/reserva/';
	} else if (pathName === '/denuncias/razoes') {
		defaultLabelPaginaAtualPlural = 'Razões das Denúncias';
		defaultLabelPaginaAtualSingular = 'Razão da Denúncia';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/denuncias/filtros') {
		defaultLabelPaginaAtualPlural = 'Filtro de Palavras';
		defaultLabelPaginaAtualSingular = 'Filtro de Palavra';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/denuncias/filtrados') {
		defaultLabelPaginaAtualPlural = 'Publicações Filtradas';
		defaultLabelPaginaAtualSingular = 'Publicação Filtrada';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/denuncias') {
		defaultLabelPaginaAtualPlural = 'Denúncias';
		defaultLabelPaginaAtualSingular = 'Denúncia';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/publicacoes') {
		defaultLabelPaginaAtualPlural = 'Publicações';
		defaultLabelPaginaAtualSingular = 'Publicação';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/shortcuts') {
		defaultLabelPaginaAtualPlural = 'Facilidades';
		defaultLabelPaginaAtualSingular = 'Facilidade';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/enquetes') {
		defaultLabelPaginaAtualPlural = 'Enquetes';
		defaultLabelPaginaAtualSingular = 'Enquete';

		defaultIcon = iconPublicacoes;
	} else if (pathName === '/descontos/vouchers') {
		defaultLabelPaginaAtualTipo = 'Desconto';

		defaultLabelPaginaAtualPlural = 'Vouchers';
		defaultLabelPaginaAtualSingular = 'Voucher';

		defaultIcon = iconDescontos;

		defaultRouteNewRegister = '/new-register/desconto';
		defaultRouteUpdateRegister = '/update/desconto/';
	} else if (pathName === '/descontos/categorias') {
		defaultLabelPaginaAtualTipo = 'Lojistas';

		defaultLabelPaginaAtualPlural = 'Categorias';
		defaultLabelPaginaAtualSingular = 'Categoria';

		defaultIcon = iconDescontos;

		defaultRouteNewRegister = '/new-register/desconto/categoria';
		defaultRouteUpdateRegister = '/update/desconto/categoria/';
	} else if (pathName === '/notificacao/lista-de-push') {
		defaultLabelPaginaAtualTipo = 'Notificação';

		defaultLabelPaginaAtualPlural = 'Push';
		defaultLabelPaginaAtualSingular = 'Push';

		defaultIcon = iconNotificacao;

		defaultRouteNewRegister = '/new-register/notificacao';
		defaultRouteUpdateRegister = '/update/notificacao/';
	} else if (pathName === '/fcg/solicitations') {
		defaultLabelPaginaAtualPlural = 'Solicitações';
		defaultLabelPaginaAtualSingular = 'Solicitação';

		defaultIcon = iconDescontos;

		defaultRouteNewRegister = '/new-register/fcg/solicitations';
		defaultRouteUpdateRegister = '/update/fcg/solicitations/';
	} else if (pathName === '/packages') {
		defaultLabelPaginaAtualPlural = 'Encomendas';
		defaultLabelPaginaAtualSingular = 'Encomenda';

		defaultIcon = iconDescontos;

		defaultRouteNewRegister = '/new-register/packages';
		defaultRouteUpdateRegister = '/update/packages/';
	}

	const packagesData = {
		headers,
		data,
	};

	const linkButtonAreaComum =
		pathName === '/reservas' ? (
			<BitLinkButton
				link={'/new-register/area-comum'}
				text={`Adicionar área comum`}
				buttonColor={'#3AABA2'}
				textColor={'#FFFFFF'}
			/>
		) : null;

	const linkButtonCategoriesShopkeeper =
		pathName === '/registrations/shopkeepers' ? (
			<BitLinkButton
				link={'/descontos/categorias'}
				text={`Adicionar Categoria`}
				buttonColor={'#3AABA2'}
				textColor={'#FFFFFF'}
			/>
		) : null;

	const linkButtonTurnShopkeepers =
		pathName === '/descontos/categorias' ? (
			<BitLinkButton
				link={'/registrations/shopkeepers'}
				text={`Voltar p/ Lojistas`}
				buttonColor={'#3AABA2'}
				textColor={'#FFFFFF'}
			/>
		) : null;

	// const verRelatorios =
	// 	pathName === '/descontos/vouchers' && typeUser === 'Administrador' ? (
	// 		<BitLinkButton
	// 			link={'/relatorios/vouchers'}
	// 			text={`Ver relatório`}
	// 			buttonColor={'#3AABA2'}
	// 			textColor={'#FFFFFF'}
	// 		/>
	// 	) : null;

	const verRelatorios = null;

	const importarXLSX =
		pathName === '/registrations/residents' &&
		(typeUser === 'Administrador' || typeUser === 'Gestor') ? (
			<BitLinkButton
				link={'/import'}
				text={`Importar XLSX`}
				buttonColor={'#3AABA2'}
				textColor={'#FFFFFF'}
			/>
		) : null;

	const exportarXLSX =
		pathName === '/registrations/residents' &&
		(typeUser === 'Administrador' || typeUser === 'Gestor') ? (
			<BitLinkButton
				link={'/export'}
				text={`Exportar XLSX`}
				buttonColor={'#3AABA2'}
				textColor={'#FFFFFF'}
			/>
		) : null;

	Object.entries(permissions).forEach(([key, value]) => {
		if (key === 'users') {
			Object.entries(value).forEach(([keyUsers, valueUsers]) => {
				if (
					allSubMenusRegistrations[keyUsers] !== undefined &&
					Array.isArray(valueUsers) &&
					valueUsers.length > 0
				) {
					subMenusRegistrations[keyUsers] =
						allSubMenusRegistrations[keyUsers];
				}
			});
		} else if (
			allSubMenusRegistrations[key] !== undefined &&
			Array.isArray(value) &&
			value.length > 0
		) {
			subMenusRegistrations[key] = allSubMenusRegistrations[key];
		}
	});

	subMenus = {};
	if (pathName.indexOf('/registrations') >= 0) {
		subMenus = subMenusRegistrations;
		defaultIcon = iconCadastro;
	} else if (pathName.indexOf('/denuncias') >= 0) {
		const subMenusDenuncias: typeSubMenus = {
			'/denuncias': {
				label: 'Denúncias',
				labelSingular: 'Denúncia',
				link: '/denuncias',
				defaultRouteNewRegister: '/new-register/denuncia',
				defaultRouteUpdateRegister: '/update/denuncia/',
			},
			'/denuncias/filtrados': {
				label: 'Publicações Filtradas',
				labelSingular: 'Publicação Filtrada',
				link: '/denuncias/filtrados',
				defaultRouteNewRegister: '/',
				defaultRouteUpdateRegister: '/update/filtrados/',
			},
		};

		if (typeUser === 'Administrador') {
			subMenusDenuncias['/denuncias/razoes'] = {
				label: 'Razões da Denúncia',
				labelSingular: 'Razão da Denúncia',
				link: '/denuncias/razoes',
				defaultRouteNewRegister: '/new-register/razao',
				defaultRouteUpdateRegister: '/update/razao/',
			};
			subMenusDenuncias['/denuncias/filtros'] = {
				label: 'Filtro de Palavras',
				labelSingular: 'Filtro de Palavra',
				link: '/denuncias/filtros',
				defaultRouteNewRegister: '/',
				defaultRouteUpdateRegister: '/',
			};
		}

		subMenus = subMenusDenuncias;
		defaultIcon = iconPublicacoes;
	} else if (pathName.indexOf('/publicacoes') >= 0) {
		subMenus = subMenusPublicacoes;
		defaultIcon = iconPublicacoes;
	} else if (pathName.indexOf('/shortcuts') >= 0) {
		subMenus = subMenusPublicacoes;
		defaultIcon = iconPublicacoes;
	} else if (pathName.indexOf('/enquetes') >= 0) {
		subMenus = subMenusPublicacoes;
		defaultIcon = iconPublicacoes;
	} else if (pathName.indexOf('/descontos/categorias') >= 0) {
		subMenus = subMenusShopkeeperCategorias;
		defaultIcon = iconDescontos;
	} else if (pathName.indexOf('/descontos') >= 0) {
		subMenus = subMenusVouchers;
		defaultIcon = iconDescontos;
	} else if (pathName.indexOf('/fcg/solicitations') >= 0) {
		subMenus = subMenusSolicitations;
		defaultIcon = iconDescontos;
	} else if (pathName.indexOf('/chat') >= 0) {
		subMenus = subMenusSolicitations;
		defaultIcon = iconDescontos;
	}

	Object.entries(subMenus).forEach(([key, sub]) => {
		// console.log(sub);
		if (pathName === sub.link) {
			defaultLabelPaginaAtualPlural = sub.label;
			defaultLabelPaginaAtualSingular = sub.labelSingular;
			defaultRouteNewRegister = sub.defaultRouteNewRegister;
			defaultRouteUpdateRegister = sub.defaultRouteUpdateRegister;
		}
	});

	const deletaRegistro = (idRegistro: string) => {
		const currentPageBeforeExclusion = currentPage;
		axios
			.delete(`${urlEndpointDelete}${idRegistro}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				toast.success(`Registro ${idRegistro} excluído com sucesso!`, {
					position: 'bottom-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				});

				setCurrentPage(0);
				setCurrentPage(currentPageBeforeExclusion);
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error('Erro ao excluir. Tente novamente mais tarde.', {
					position: 'bottom-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				});
			});
	};

	useEffect(() => {
		setHeaders(['']);
		setData([['']]);
		setSecondMenu(null);
		if (Object.keys(subMenus).length > 0) {
			setSecondMenu(
				<BitHeaderSecondMenu
					label={defaultLabelPaginaAtualTipo}
					icon={'fas fa-book'}
					subMenu={subMenus}
					pathName={pathName}
				/>,
			);
		}
	}, [pathName]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadData = () => {
		console.log('chamando de novo loadData');

		if (pathName !== '/denuncias/filtros') {
			// cancel  previous ajax if exists
			if (ajaxRequest) {
				ajaxRequest.cancel();
				// ajaxRequest = null;
			}

			// creates a new token for upcomming ajax (overwrite the previous one)
			ajaxRequest = axios.CancelToken.source();

			setHeaders(currentHeaderTable);
			setData(dataTable);
			setLastPage(1);
			setShowCarregandoTabela('');
			const developmentID = localStorage.getItem('development_id');

			// eslint-disable-next-line no-underscore-dangle
			let _empreendimentoSelecionadoUnico = null;

			if (empreendimentos.value.length === 1) {
				_empreendimentoSelecionadoUnico = `${empreendimentos.value[0].value}`;
			}

			axios
				.get(urlEndpointLista, {
					cancelToken: ajaxRequest.token,
					params: {
						...(typeUser === 'Porteiro'
							? { development_id: developmentID }
							: {}),
						type: currentTypeRegisterAPI,
						isApp: false,
						page: currentPage,
						cpf: constFilterState.cpf
							.replace('.', '')
							.replace('-', '')
							.replace('_', ''),
						cnpj: constFilterState.cnpj
							.replace('.', '')
							.replace('-', '')
							.replace('/', '')
							.replace('_', ''),
						name: constFilterState.nome,
						title: constFilterState.titulo,
						development:
							_empreendimentoSelecionadoUnico ||
							constFilterState.empreendimento,
						city: constFilterState.cidade,
						phone: constFilterState.telefone
							.replace('(', '')
							.replace(')', '')
							.replace('-', '')
							.replace(' ', '')
							.replace('_', ''),
						date: constFilterState.dataCadastro,
						common_area: constFilterState.areaComum,
						cupom: constFilterState.cupom,
						tags: constFilterState.tags,
						status: constFilterState.statusSolicitacoes,
					},
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						development_id: constFilterState.empreendimento,
						status: constFilterState.statusSolicitacoes,
					},
				})
				.then(function (response: typeResponseAxios) {
					dataTable = [];
					setShowCarregandoTabela('hidden');

					// console.log(response.data.data.data);

					if (
						response.data.data.data !== '' &&
						response.data.data.data !== undefined
					) {
						setLastPage(response.data.data.last_page);

						const dadosTabelasTratados = Object.values(
							response.data.data.data,
						);

						dadosTabelasTratados.map(
							(valueDadosTabela: any, indexDadosTabela: any) => {
								dataTable[indexDadosTabela] = [];
								currentHeaderTable.map(currentHeader => {
									// console.log(currentHeader);
									// console.log(valueDadosTabela);

									if (
										currentHeader === 'CPF' &&
										valueDadosTabela.user?.support_user
											.cpf !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.user.support_user
												.cpf,
										);
									} else if (
										currentHeader === 'E-mail' &&
										valueDadosTabela.user?.email !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.user.email,
										);
									} else if (
										currentHeader === 'Cupom de desconto' &&
										valueDadosTabela.voucher !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.voucher,
										);
									} else if (
										currentHeader === 'Denúncia' &&
										valueDadosTabela.denunciation !==
											undefined
									) {
										if (
											valueDadosTabela.denunciation !==
											null
										) {
											dataTable[indexDadosTabela].push(
												`${valueDadosTabela.denunciation.substr(
													0,
													20,
												)}...`,
											);
										} else {
											dataTable[indexDadosTabela].push(
												'',
											);
										}
									} else if (
										currentHeader === 'Descrição' &&
										valueDadosTabela.description !==
											undefined
									) {
										if (
											valueDadosTabela.description !==
											null
										) {
											dataTable[indexDadosTabela].push(
												`${valueDadosTabela.description.substr(
													0,
													25,
												)}...`,
											);
										} else {
											dataTable[indexDadosTabela].push(
												'',
											);
										}
									} else if (
										currentHeader === 'Descrição' &&
										valueDadosTabela.body !== undefined
									) {
										if (valueDadosTabela.body !== null) {
											dataTable[indexDadosTabela].push(
												`${valueDadosTabela.body.substr(
													0,
													25,
												)}...`,
											);
										} else {
											dataTable[indexDadosTabela].push(
												'',
											);
										}
									} else if (currentHeader === 'Recebido') {
										dataTable[indexDadosTabela].push(``);
									} else if (currentHeader === 'Visualizou') {
										dataTable[indexDadosTabela].push(``);
									} else if (
										currentHeader === 'Título' &&
										valueDadosTabela.title !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.title,
										);
									} else if (
										currentHeader === 'Razão' &&
										valueDadosTabela.reason !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.reason,
										);
									} else if (
										currentHeader === 'Tags' &&
										valueDadosTabela.tags !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.tags,
										);
									} else if (
										currentHeader === 'Tipo' &&
										valueDadosTabela.type !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.type === 'chat'
												? 'Chat Simples'
												: valueDadosTabela.type ===
												  'publication'
												? 'Publicação'
												: valueDadosTabela.type ===
												  'group_chat'
												? 'Chat em Grupo'
												: '',
										);
									} else if (
										currentHeader === 'Ref' &&
										valueDadosTabela.relationship_id !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.relationship_id,
										);
									} else if (
										currentHeader === 'Anunciante' &&
										valueDadosTabela.user.name !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.user.name,
										);
									} else if (
										currentHeader ===
											'Nome do proprietário' &&
										valueDadosTabela.user.name !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.user.name,
										);
									} else if (
										currentHeader === 'Solicitante' &&
										valueDadosTabela.user.name !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.user.name,
										);
									} else if (
										currentHeader === 'Status' &&
										valueDadosTabela.status !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.status,
										);
									} else if (
										currentHeader === 'Motivo' &&
										valueDadosTabela.reason !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.reason,
										);
									} else if (
										currentHeader === 'Empreendimento' &&
										valueDadosTabela.development?.name !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.development.name,
										);
									} else if (
										currentHeader === 'Área comum' &&
										valueDadosTabela.common_area.name !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.common_area.name,
										);
									} else if (
										currentHeader ===
											'Data de agendamento' &&
										valueDadosTabela.scheduling_date !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											Moment(
												valueDadosTabela.scheduling_date,
											).format('DD/MM/YYYY'),
										);
									} else if (
										currentHeader ===
											'Horário agendamento' &&
										valueDadosTabela.scheduling_time !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.scheduling_time,
										);
									} else if (
										currentHeader === 'ID' &&
										valueDadosTabela.id !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.id,
										);
									} else if (
										currentHeader === 'Nome' &&
										valueDadosTabela.name !== undefined
									) {
										if (
											pathName ===
												'/registrations/enterprises' ||
											pathName ===
												'/registrations/shopkeepers'
										) {
											dataTable[indexDadosTabela].push(
												<button
													type="button"
													style={{
														textDecoration:
															'underline',
													}}
													onClick={(e: any) => {
														openModal(
															e,
															valueDadosTabela,
														);
													}}
												>
													{valueDadosTabela.name}
												</button>,
											);
										} else {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.name,
											);
										}
									} else if (
										currentHeader === 'Estado' &&
										valueDadosTabela.state !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.state,
										);
									} else if (
										currentHeader === 'Cidade' &&
										valueDadosTabela.city !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.city,
										);
									} else if (
										currentHeader === 'Loteamentos'
									) {
										dataTable[indexDadosTabela].push('');
									} else if (
										currentHeader === 'Proprietários'
									) {
										dataTable[indexDadosTabela].push('');
									} else if (
										currentHeader === 'Telefone' &&
										valueDadosTabela.phone !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.phone,
										);
									} else if (
										currentHeader === 'Link' &&
										valueDadosTabela.link !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.link,
										);
									} else if (
										currentHeader === 'E-mail' &&
										valueDadosTabela.email !== undefined
									) {
										dataTable[indexDadosTabela].push(
											valueDadosTabela.email,
										);
									} else if (currentHeader === 'Plano') {
										dataTable[indexDadosTabela].push('');
									} else if (
										currentHeader === 'Responsável'
									) {
										dataTable[indexDadosTabela].push('');
									} else if (
										currentHeader === 'Data de cadastro' &&
										valueDadosTabela.created_at !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											Moment(
												valueDadosTabela.created_at,
											).format('DD/MM/YYYY'),
										);
									} else if (
										currentHeader === 'Data/Hora Disparo' &&
										valueDadosTabela.created_at !==
											undefined
									) {
										dataTable[indexDadosTabela].push(
											`${Moment(
												valueDadosTabela.publication_date,
											).format('DD/MM/YYYY')} - ${
												valueDadosTabela.publication_hour
											}`,
										);
									} else if (
										currentHeader === 'Ativada' &&
										valueDadosTabela.active !== undefined
									) {
										dataTable[indexDadosTabela].push(
											// eslint-disable-next-line no-extra-boolean-cast
											Boolean(valueDadosTabela.active)
												? 'Sim'
												: 'Não disparado',
										);
									} else if (
										currentHeader === 'Categoria' &&
										valueDadosTabela.categories !==
											undefined
									) {
										const categoriasList =
											valueDadosTabela.categories;

										if (categoriasList.length > 0) {
											const categoriasImploded = categoriasList
												.map(
													(categoria: any) =>
														categoria.title,
												)
												.join(';');
											dataTable[indexDadosTabela].push(
												categoriasImploded,
											);
										} else {
											dataTable[indexDadosTabela].push(
												'',
											);
										}
									} else if (
										currentHeader === 'Empreendimentos' &&
										valueDadosTabela.developments !==
											undefined
									) {
										const empreendimentosList =
											valueDadosTabela.developments;

										if (empreendimentosList.length > 0) {
											const empreendimentosImploded = empreendimentosList
												.map(
													(empreendimento: any) =>
														empreendimento.name,
												)
												.join(';');
											dataTable[indexDadosTabela].push(
												empreendimentosImploded,
											);
										} else {
											dataTable[indexDadosTabela].push(
												'',
											);
										}
									} else if (
										currentHeader === '' &&
										valueDadosTabela.id !== undefined
									) {
										// if (
										// 	urlEndpointDelete ===
										// 	`${urlEndpoint}/pushs/delete/`
										// ) {
										// 	dataTable[indexDadosTabela].push(
										// 		<div className="inline">
										// 			<Link
										// 				to={`${defaultRouteUpdateRegister}${valueDadosTabela.id}`}
										// 			>
										// 				<i className="far fa-plus-square mr-2" />
										// 			</Link>
										// 		</div>,
										// 	);
										// } else {
										dataTable[indexDadosTabela].push(
											<div className="inline">
												<Link
													to={`${defaultRouteUpdateRegister}${valueDadosTabela.id}`}
												>
													<i className="far fa-plus-square mr-2" />
												</Link>
												{defaultRouteUpdateRegister !==
												'/update/fcg/solicitations/' ? (
													<button
														type="button"
														onClick={e => {
															if (
																// eslint-disable-next-line no-alert
																window.confirm(
																	`Você realmente deseja deletar o registro com o ID ${valueDadosTabela.id}?`,
																)
															) {
																deletaRegistro(
																	valueDadosTabela.id,
																);
															}
														}}
													>
														<i className="far fa-minus-square" />
													</button>
												) : (
													<></>
												)}
											</div>,
										);
										// }
									}

									if (
										valueDadosTabela.support_user !==
											undefined &&
										valueDadosTabela.support_user !== null
									) {
										if (
											currentHeader === 'CPF' &&
											valueDadosTabela.support_user
												.cpf !== undefined
										) {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.support_user
													.cpf,
											);
										} else if (
											currentHeader === 'CNPJ' &&
											valueDadosTabela.support_user
												.cnpj !== undefined
										) {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.support_user
													.cnpj,
											);
										} else if (
											currentHeader === 'Setor' &&
											valueDadosTabela.support_user
												.sector !== undefined
										) {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.support_user
													.sector,
											);
										} else if (
											currentHeader === 'Cidade' &&
											valueDadosTabela.support_user
												.city !== undefined
										) {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.support_user
													.city,
											);
										} else if (
											currentHeader === 'Telefone' &&
											valueDadosTabela.support_user
												.phone !== undefined
										) {
											dataTable[indexDadosTabela].push(
												valueDadosTabela.support_user
													.phone,
											);
										} else if (
											currentHeader ===
												'Data Nascimento' &&
											valueDadosTabela.support_user
												.birth_date !== undefined
										) {
											dataTable[indexDadosTabela].push(
												Moment(
													valueDadosTabela
														.support_user
														.birth_date,
												).format('DD/MM/YYYY'),
											);
										}
									}

									if (
										valueDadosTabela.user_development !==
											null &&
										valueDadosTabela.user_development !==
											undefined
									) {
										if (
											currentHeader ===
												'Empreendimento' &&
											valueDadosTabela.allDevelopments !==
												undefined
										) {
											const allDevelopments: any[] = [];

											// @ts-ignore
											valueDadosTabela.allDevelopments.map(
												(dev: {
													development: { name: any };
												}) => {
													allDevelopments.push(
														dev.development.name,
													);

													return null;
												},
											);

											dataTable[indexDadosTabela].push(
												allDevelopments.join(', '),
											);
										}
									} else if (
										currentHeader === 'Empreendimento'
									) {
										// dataTable[indexDadosTabela].push('');
									}

									return null;
								});

								return null;
							},
						);

						setHeaders(currentHeaderTable);
						setData(dataTable);
					}
				})
				.catch(function (error) {
					if (!axios.isCancel(error)) {
						setShowCarregandoTabela('hidden');
					}
					console.log('Register filter', error);
				});
		}
	};

	useEffect(() => {
		console.log('chamando de novo useEffect');
		if (empreendimentos?.value?.length) {
			loadData();
		}
	}, [pathName, constFilterState, currentPage, empreendimentos.value]);
	
	console.log('chamando', [
		pathName,
		constFilterState,
		currentPage,
		empreendimentos.value,
	]);

	useEffect(() => {
		setCurrentPage(1);
	}, [pathName, constFilterState]);

	const botoesPagination = [];

	const maxPagesShow = 2;

	for (
		let i = currentPage - maxPagesShow > 1 ? currentPage - maxPagesShow : 1;
		i <= maxPagesShow + currentPage;
		i += 1
	) {
		if (i >= 1 && i <= lastPage) {
			botoesPagination.push(i);
		}
	}

	const paginationHTML =
		lastPage > 1 ? (
			<nav
				className="relative z-0 inline-flex shadow-sm -space-x-px pagination-bar"
				aria-label="Pagination"
			>
				<button
					type="button"
					className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
					onClick={() => {
						setCurrentPage(1);
					}}
				>
					<span>
						<i className="fas fa-chevron-left pl-2" />
						<i className="fas fa-chevron-left -ml-1 pr-2" />
					</span>
				</button>
				<button
					type="button"
					className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
					onClick={() => {
						const newCurrentPage =
							currentPage > 1 ? currentPage - 1 : 1;
						setCurrentPage(newCurrentPage);
					}}
				>
					<span>
						<i className="fas fa-chevron-left pl-2 pr-2" />
					</span>
				</button>
				{botoesPagination.map(function (index) {
					const isActiveButton =
						index === currentPage ? 'active' : '';
					return (
						<button
							type="button"
							className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 ${isActiveButton}`}
							onClick={() => setCurrentPage(index)}
						>
							{index}
						</button>
					);
				})}
				<button
					type="button"
					className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
					onClick={() => {
						const newCurrentPage =
							currentPage < lastPage ? currentPage + 1 : lastPage;
						setCurrentPage(newCurrentPage);
					}}
				>
					<span>
						<i className="fas fa-chevron-right pl-2 pr-2" />
					</span>
				</button>
				<button
					type="button"
					className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
					onClick={() => {
						setCurrentPage(lastPage);
					}}
				>
					<span>
						<i className="fas fa-chevron-right pl-2" />
						<i className="fas fa-chevron-right -ml-1 pr-2" />
					</span>
				</button>
			</nav>
		) : null;

	return (
		<BitPage secondMenu={secondMenu}>
			<BitHeaderFull
				label={`${defaultLabelPaginaAtualTipo}  / ${defaultLabelPaginaAtualPlural}`}
				icon={''}
				hasIcon={false}
			/>

			{pathName !== '/denuncias/filtros' ? <BitFilters /> : <></>}

			<div className="p-5 mb-4 bg-white rounded">
				<h2 className="mb-6 mt-4">
					<div className="black text-3xl font-medium inline-flex items-center">
						<img
							src={defaultIcon}
							alt=""
							className="inline-block mr-4 icone-internas-cadastros"
						/>
						{defaultLabelPaginaAtualPlural}
					</div>
					{!(
						defaultRouteUpdateRegister ===
							'/update/fcg/solicitations/' ||
						defaultRouteUpdateRegister === '/update/denuncia/'
					) ? (
						pathName !== '/denuncias/filtros' &&
						pathName !== '/denuncias/filtrados' ? (
							<div className="float-right">
								<BitLinkButton
									link={defaultRouteNewRegister}
									text={`Adicionar ${defaultLabelPaginaAtualSingular.toLowerCase()}`}
									buttonColor={'#3AABA2'}
									textColor={'#FFFFFF'}
								/>
							</div>
						) : (
							<></>
						)
					) : (
						<></>
					)}
					<div className="float-right pr-3">
						{linkButtonAreaComum}
						{verRelatorios}
						{importarXLSX}
						{linkButtonCategoriesShopkeeper}
						{linkButtonTurnShopkeepers}
					</div>
					{exportarXLSX !== null ? (
						<div className="float-right pr-3">{exportarXLSX}</div>
					) : null}
				</h2>

				<div className="mt-3 mb-3">
					<p>
						{pathName === '/registrations/enterprises' ||
						pathName === '/registrations/shopkeepers'
							? `Para exibir as informações do empreendimento clique sobre o nome do empreendimento desejado na tabela`
							: null}
					</p>
				</div>

				{pathName !== '/denuncias/filtros' ? (
					<BitTable
						tableData={packagesData}
						noneMessage={`Nenhum ${defaultLabelPaginaAtualSingular.toLowerCase()} encontrado.`}
					/>
				) : (
					<div className="p-5 mb-4 bg-white rounded">
						<h4 className="font-bold mb-4">
							IMPORTAÇÃO DE PALAVRAS PROIBIDAS NO SISTEMA VIA
							PLANILHA
						</h4>

						<h3 className="pb-8">
							<b>ATENÇÃO: </b>
							Nunca remova a primeira linha onde está escrito
							<strong> PALAVRAS</strong>
							.
						</h3>
						<h3 className="pb-8">
							<b>ATENÇÃO²: </b>
							Sempre que desejar incluir nova(s) palavra(s),
							exporte a planilha pelo botão abaixo, adicione a(s)
							mesma(s) após a última e realize a importação.
						</h3>

						<div
							style={{
								paddingBottom: '1rem',
							}}
						>
							<BitLinkButton
								link={urlEndpointExportaPalavras}
								external={true}
								text={`EXPORTAR PALAVRAS`}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
							/>
						</div>

						<form>
							<div className="custom-file mb-4">
								<p>Selecione a planilha que deseja importar:</p>
								<button
									type="button"
									className="bloco-solta-arquivos flex items-center w-full"
									onClick={(e: any) => {
										const inputArquivoCategoria = document.getElementById(
											'arquivoCategoria',
										);
										if (inputArquivoCategoria !== null) {
											inputArquivoCategoria.click();
										}
									}}
								>
									<div className="icone-solta-arquivos">
										<img
											src={iconArquivos}
											alt=""
											width="26"
											className="mr-4"
										/>
									</div>
									<div className="texto-solta-arquivos text-left">
										<p className="text-lg">
											<b>ADICIONAR XLSX</b>
										</p>
										<p className="text-sm">
											Adicione arquivos até 100MB
											(Extensões: .xls, .xlsx)
										</p>
									</div>
								</button>
								<input
									type="file"
									id="arquivoCategoria"
									name="arquivoCategoria"
									className="hidden"
									onChange={onChangeHandler}
								/>
								{uploadedFile ? (
									<p>
										Arquivo Selecionado:
										{uploadedFile?.name}
									</p>
								) : (
									<></>
								)}
							</div>
						</form>

						<div className="text-center pb-8">
							{retornoImportaWords}
						</div>

						<div className="text-right">
							<BitButton
								text={'IMPORTAR PALAVRAS'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								type={'fit px-10'}
								classesBlockOption={'inline-block'}
								onClick={onUploadHandler}
							/>
						</div>
					</div>
				)}

				{pathName !== '/denuncias/filtros' ? (
					<div className="text-center mt-6">{paginationHTML}</div>
				) : (
					<></>
				)}
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
			<div className={`carregando-tabela ${showCarregandoTabela}`}>
				<i className="fas fa-spinner fa-spin" />
				<div className="background-carrega-tabela" />
			</div>
			<BitModal
				label={labelModal}
				description={descriptionModal}
				open={modalShow.open}
				thumbnailHeader={thumbnailHeaderModal}
				maxWSize={maxWSizeModal}
			>
				{contentModal}
			</BitModal>
		</BitPage>
	);
};

export default Registers;
